.service-card {
    background: #fff;
    border: none;
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: center;
    max-width: 350px;
}

.service-card h2 {
    font-size: 1.5rem;
    margin: 0.5rem 0;
}

.service-card p {
    font-size: 1rem;
    margin: 0.5rem 0 1rem;
}

.service-card__btn-group {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}

.service-card__btn {
    background: #2575fc;
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    font-weight: 600;
    text-transform: uppercase;
    transition: background 0.3s;
}

.service-card__btn:hover {
    background: #0056b3;
}