body {
    font-family: 'Poppins', sans-serif;
    color: #444;
    background-color: #f5f5f5;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

.header {
    background: linear-gradient(135deg, #6a11cb, #2575fc);
    color: white;
    padding: 2rem 3rem;
    border-bottom-left-radius: 50px;
}

.header h1 {
    margin: 0;
    font-size: 2.5rem;
}

.catalog {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem;
    gap: 1.5rem;
}

.disabled {
    background: #3d3d3d;
}

.footer {
    text-align: center;
    background: #f1f1f1;
    padding: 1rem;
    font-size: 0.9rem;
}

.catalog.hidden {
    text-align: center;
    font-size: 1.2rem;
    color: #777;
    margin-top: 2rem;
}