.auth-form {
    max-width: 40rem;
}
.auth-form input {
    display: block;
    width: 80%;
    margin: 0.5rem 0;
    padding: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ccc;
}
  
.auth-form p {
    color: #007bff;
    text-align: center;
    margin-top: 1rem;
}
  
.auth-form p:hover {
    text-decoration: underline;
}

.auth-form__btn {
    color: white;
    padding: 0.5rem 1.5rem;
    border-radius: 20px;
    font-weight: 600;
    text-transform: uppercase;
    transition: background 0.3s;
    margin-right: 0.5rem;
    background: #4caf50;
}
  
.auth-form__btn:hover {
    cursor: pointer;
    background: #3d8b41;
}